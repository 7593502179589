<template>

    <div class="w-full h-full flex flex-col relative overflow-x-hidden">

        <swiper ref="mySwiper" :options="swiperOptions" class="swiper h-full w-full">

            <SlideSL @nextPage="nextPage" :sortBy="sortBy" @changeSortBy="changeSortBy"/>
            <SlideSMU :sortBy="sortBy"/>

        </swiper>

    </div>

</template>

<script>
const SlideSMU = () => import("@/components/Mobile/Pipeline/SlideSMUSL/SlideSMU");
const SlideSL = () => import("@/components/Mobile/Pipeline/SlideSMUSL/SlideSL");

export default {
    components: {
        SlideSMU,
        SlideSL
    },
    data() {
        return {
            swiperElement: null,
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            sortBy:{ name: 'Name', value: 'name', direction: 'asc' }
        }
    },
    methods:{
        nextPage(){
            this.$refs.mySwiper.swiper.slideTo(1, 300)
        },
        changeSortBy(sortB){
            this.sortBy = sortB
        }
    },
    mounted(){

    }
}

</script>

<style lang="scss" scoped>

    .swiper-pagination {
        position: absolute;
        text-align: center;
        transition: 300ms opacity;
        transform: translate3d(0, 0, 0);
        z-index: 9999999;
        top: 30px;
    }

</style>